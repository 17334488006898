/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import 'animate.css';
// animation
@for $i from 1 through 20 {
    .duration:nth-child(#{$i}) {
        animation-duration: (0.2s * $i);
    }
}

.fast {
    animation-duration: (.6s);
}

// fonts
@font-face {
    font-family: "medium";
    src: url("./assets/fonts/medium.ttf");
}

@font-face {
    font-family: "regular";
    src: url("./assets/fonts/light.ttf");
}

@font-face {
    font-family: "light";
    src: url("./assets/fonts/light.ttf");
}

@font-face {
    font-family: "bold";
    src: url("./assets/fonts/bold.ttf");
}

* {
    font-family: "light";
    ion-input {
        font-family: "light" !important;
    }
}

ion-content,
ion-header {
    direction: rtl;
}

ion-header {
    &::after {
        display: none !important;
    }
    ion-toolbar {
        --background: var(--white-background);
        padding-inline: 16px !important;
    }
}

.header-ios ion-toolbar:last-of-type {
    --border-width: 0 !important;
}

ion-content {
    --background: var(--light-background)
}

ion-footer {
    background: var(--white-background);
}

ion-back-button {
    transform: rotateY(180deg);
}

ion-fab-button {
    ion-icon {
        color: #FFF
    }
}

.error-view {
    background: var(--white-background);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    z-index: 99999;
    .content {
        text-align: center;
        ion-icon {
            font-size: 64px;
        }
    }
}

.loading {
    background: var(--white-background);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    z-index: 99999;
}

.flex_between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex_start {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.container {
    margin: auto !important;
    max-width: 768px;
}

.qrCode {
    --width: 350px;
    --height: 400px;
}
/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import 'animate.css';
.duration:nth-child(1) {
  animation-duration: 0.2s;
}

.duration:nth-child(2) {
  animation-duration: 0.4s;
}

.duration:nth-child(3) {
  animation-duration: 0.6s;
}

.duration:nth-child(4) {
  animation-duration: 0.8s;
}

.duration:nth-child(5) {
  animation-duration: 1s;
}

.duration:nth-child(6) {
  animation-duration: 1.2s;
}

.duration:nth-child(7) {
  animation-duration: 1.4s;
}

.duration:nth-child(8) {
  animation-duration: 1.6s;
}

.duration:nth-child(9) {
  animation-duration: 1.8s;
}

.duration:nth-child(10) {
  animation-duration: 2s;
}

.duration:nth-child(11) {
  animation-duration: 2.2s;
}

.duration:nth-child(12) {
  animation-duration: 2.4s;
}

.duration:nth-child(13) {
  animation-duration: 2.6s;
}

.duration:nth-child(14) {
  animation-duration: 2.8s;
}

.duration:nth-child(15) {
  animation-duration: 3s;
}

.duration:nth-child(16) {
  animation-duration: 3.2s;
}

.duration:nth-child(17) {
  animation-duration: 3.4s;
}

.duration:nth-child(18) {
  animation-duration: 3.6s;
}

.duration:nth-child(19) {
  animation-duration: 3.8s;
}

.duration:nth-child(20) {
  animation-duration: 4s;
}

.fast {
  animation-duration: 0.6s;
}

@font-face {
  font-family: "medium";
  src: url("./assets/fonts/medium.ttf");
}
@font-face {
  font-family: "regular";
  src: url("./assets/fonts/light.ttf");
}
@font-face {
  font-family: "light";
  src: url("./assets/fonts/light.ttf");
}
@font-face {
  font-family: "bold";
  src: url("./assets/fonts/bold.ttf");
}
* {
  font-family: "light";
}
* ion-input {
  font-family: "light" !important;
}

ion-content,
ion-header {
  direction: rtl;
}

ion-header::after {
  display: none !important;
}
ion-header ion-toolbar {
  --background: var(--white-background);
  padding-inline: 16px !important;
}

.header-ios ion-toolbar:last-of-type {
  --border-width: 0 !important;
}

ion-content {
  --background: var(--light-background) ;
}

ion-footer {
  background: var(--white-background);
}

ion-back-button {
  transform: rotateY(180deg);
}

ion-fab-button ion-icon {
  color: #FFF;
}

.error-view {
  background: var(--white-background);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  z-index: 99999;
}
.error-view .content {
  text-align: center;
}
.error-view .content ion-icon {
  font-size: 64px;
}

.loading {
  background: var(--white-background);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  z-index: 99999;
}

.flex_between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex_start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.container {
  margin: auto !important;
  max-width: 768px;
}

.qrCode {
  --width: 350px;
  --height: 400px;
}